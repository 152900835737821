import React from 'react';
import { NavLink } from 'react-router-dom';

const NavBar: React.FC = () => {
    return (
        <>
            {/* <!-- NAVBAR START --> */}
            <section className="bg-blue-950 text-emerald-300 font-bold">
                <nav className="container mx-auto py-3">
                    <div className="flex gap-x-4 uppercase">
                        <NavLink
                            to="/"
                            className={({ isActive }) =>
                                !isActive ? "hover:text-white hover:underline" : "text-white"
                            }
                        >
                            <span>Início</span>
                        </NavLink>

                        <NavLink
                            to="/produtos"
                            className={({ isActive }) =>
                                !isActive ? "hover:text-white hover:underline" : "text-white"
                            }
                        >
                            <span>Productos</span>
                        </NavLink>

                        <NavLink
                            to="/contactos"
                            className={({ isActive }) =>
                                !isActive ? "hover:text-white hover:underline" : "text-white"
                            }
                        >
                            <span>Contactos</span>
                        </NavLink>

                        {/* <NavLink
                            to="/quem-somos"
                            className={({ isActive }) =>
                                !isActive ? "hover:text-white hover:underline" : "text-white"
                            }
                        >
                            <span>Sobre nós</span>
                        </NavLink> */}
                    </div>
                </nav>
            </section>
            {/* <!-- NAVBAR END --> */}
        </>
    );
}

export default NavBar;