import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';
import { IMG } from '../constants';

type TProps = {
  children?: React.ReactNode;
}

export default function Slider({ children }: TProps) {
  const images = [{
    backgroundImage: `url(${IMG.WALLPAPER})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    width: '100%',
  },
  {
    backgroundImage: `url(${IMG.WALLPAPER})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    width: '100%',
  },
  {
    backgroundImage: `url(${IMG.WALLPAPER})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    width: '100%',
  }];
  // const images = ['https://images.pexels.com/photos/16770561/pexels-photo-16770561/free-photo-of-scenic-view-of-green-hills-and-mountains.jpeg', 'https://images.pexels.com/photos/20041507/pexels-photo-20041507/free-photo-of-close-up-of-sleeping-cat.jpeg', 'https://images.pexels.com/photos/12187128/pexels-photo-12187128.jpeg', 'https://images.pexels.com/photos/11785594/pexels-photo-11785594.jpeg']

  return (
    <>
      <Swiper
        slidesPerView={1}
        // spaceBetween={30}
        loop
        autoplay
        pagination={{
          clickable: true,
        }}
        navigation
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {images.map((img, index) => (
          <SwiperSlide key={index}>
            <a href='/#'>
              {/* <img
                src={img}
                alt={`slide-${index + 1}`}
                className='w-fit h-[500px] mx-auto select-none'
              /> */}
              <div style={img}>{children}</div>
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
