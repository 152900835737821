import React, { useEffect, useState } from 'react';
import Slider from "../../components/Slider";
import { IMG } from "../../constants";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import ProductList from "./components/ProductList";
import SideBar from "./components/SideBar";
import TopBar from "./components/TopBar";
import TopProducts from "./components/TopProducts";
import { api } from '../../services/api';
import Button from '../../components/button';
import { Link, NavLink, Navigate } from 'react-router-dom';
import WhatsAppContact from '../../components/WhatsAppContact';
import whatsAppApi from '../../services/whatsAppApi';

export function Shop() {
    const [data, setData] = useState([]);

    const img = {
        backgroundImage: `url(${IMG.LOGOY})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: 80,
        width: 80
    };

    const contacts = {
        backgroundImage: `url(${IMG.CONTACTUS})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: 500,
        width: '100%',
    };

    useEffect(() => {
        api.get("/produtos").then((response) => {
            if (response.data) {
                setData(response.data.produtos)
            }
        }).catch((err) => {
            console.error("Error in data fetch:", err);
        });
    }, [])

    return (
        <div>
            <div className="w-full h-[350px]">
                {/* <div style={img} /> */}
                <Slider />

            </div>

            <div className="flex items-center p-[10px] bg-[#f2fff4]">
                <div className="flex flex-1 justify-evenly">
                    <div className="flex flex-col items-center">
                        <svg
                            width="50"
                            height="50"
                            viewBox="0 0 50 50"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill="#31883A"
                                d="M4.44,14h5.87a.73.73,0,1,0,0-1.46H4.44a.73.73,0,1,0,0,1.46Z"
                            />

                            <path
                                fill="#31883A"
                                d="M10.31,16.19H8.11a.74.74,0,1,0,0,1.47h2.2a.74.74,0,1,0,0-1.47Z"
                            />

                            <path
                                fill="#31883A"
                                d="M5.91,17.66a.74.74,0,0,0,0-1.47H2.23a.74.74,0,0,0,0,1.47Z"
                            />

                            <path
                                fill="#31883A"
                                d="M10.31,19.86H7.37a.74.74,0,0,0,0,1.47h2.94a.74.74,0,1,0,0-1.47Z"
                            />

                            <path
                                fill="#31883A"
                                d="M40.49,16.92H36v-.85a4.3,4.3,0,0,0-4.3-4.29H16.81a4.3,4.3,0,0,0-4.29,4.29V34.55a.73.73,0,0,0,.73.73h2.28a3.54,3.54,0,0,0,.68,1.47H3a.74.74,0,1,0,0,1.47h44.8a.74.74,0,0,0,0-1.47h-3a3.71,3.71,0,0,0,.68-1.51,3.34,3.34,0,0,0,3-3.32v-7A8,8,0,0,0,40.49,16.92Zm0,1.47A6.54,6.54,0,0,1,47,24.93v3.74H36V18.39ZM14,16.07a2.83,2.83,0,0,1,2.83-2.82H31.72a2.83,2.83,0,0,1,2.83,2.82v12.6H14Zm2.94,18.48a2.21,2.21,0,1,1,2.2,2.2A2.21,2.21,0,0,1,16.92,34.55Zm5.8.73H38.29A3.67,3.67,0,0,0,39,36.75H22A3.54,3.54,0,0,0,22.72,35.28Zm17-.73a2.2,2.2,0,0,1,4.39-.1s0,.06,0,.1,0,.06,0,.09a2.2,2.2,0,0,1-4.39-.09ZM47,31.92a1.89,1.89,0,0,1-1.55,1.86,3.67,3.67,0,0,0-7.19,0H22.72a3.67,3.67,0,0,0-7.19,0H14V30.14H47Z"
                            />

                            <path
                                fill="#31883A"
                                d="M37.48,25.73h8.08A.74.74,0,0,0,46.3,25a5.14,5.14,0,0,0-5.14-5.14H37.48a.73.73,0,0,0-.73.73V25A.73.73,0,0,0,37.48,25.73Zm.74-4.4h2.94a3.67,3.67,0,0,1,3.59,2.94H38.22Z"
                            />
                        </svg>
                        <div className="flex flex-col items-center">
                            <span className="font-bold uppercase text-[#31883A] text-sm">Entragas rápidas</span>
                            <span className="font-medium text-sm">Receba tudo em seu conforto</span>
                        </div>
                    </div>

                    <Link
                        to="/contactos"
                        className="flex flex-col items-center cursor-pointer hover:text-[#31883A]/75"
                    >
                        <svg
                            width="50"
                            height="50"
                            viewBox="0 0 50 50"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill="#31883A"
                                d="M25.1,16.44a4,4,0,1,0,4,4A4,4,0,0,0,25.1,16.44Zm2.44,4A2.45,2.45,0,1,1,25.1,18,2.44,2.44,0,0,1,27.54,20.48Z"
                            />

                            <path
                                fill="#31883A"
                                d="M32.5,13.07h0a10.6,10.6,0,0,0-15,0c-3.7,3.7-4.17,10.66-1,14.88L25,40.23,33.49,28C36.66,23.74,36.21,16.77,32.5,13.07Zm-.29,14L25,37.44,17.78,27a10.41,10.41,0,0,1,.84-12.83,9,9,0,0,1,12.76,0A10.41,10.41,0,0,1,32.21,27Z"
                            />
                        </svg>
                        <div className="flex flex-col items-center">
                            <span className="font-bold uppercase text-[#31883A] text-sm">Onde estamos</span>
                            <span className="font-medium text-sm">Descubra onde nos encontrar</span>
                        </div>
                    </Link>

                    <div
                        className="flex flex-col items-center cursor-pointer hover:text-[#31883A]/75"
                        onClick={whatsAppApi}
                    >
                        <svg
                            width="50"
                            height="50"
                            viewBox="0 0 50 50"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill="#31883A"
                                d="M25,10.94a12.91,12.91,0,1,1-4.78,24.9l-.65-.26L19,36l-3.34,2.45,1-3.87.18-.72-.55-.5A12.91,12.91,0,0,1,25,10.94m0-1.25a14.15,14.15,0,0,0-9.58,24.58L13.61,41.5,19.75,37A14.16,14.16,0,1,0,25,9.69Z"
                            />

                            <path
                                fill="#31883A"
                                d="M24.12,25a3.3,3.3,0,0,1,.58-.91c.23-.25.54-.54.91-.88a7.85,7.85,0,0,0,1.05-1.07A1.79,1.79,0,0,0,27,21.07a1.67,1.67,0,0,0-.67-1.39,3,3,0,0,0-1.86-.52,3.78,3.78,0,0,0-3.16,1.4L20,19.64a4.78,4.78,0,0,1,1.9-1.47,6.57,6.57,0,0,1,2.68-.51,5,5,0,0,1,3.06.84,2.73,2.73,0,0,1,1.13,2.31,3.21,3.21,0,0,1-.24,1.28A3.28,3.28,0,0,1,28,23c-.22.24-.52.54-.9.88A7.52,7.52,0,0,0,26,25.05a2.09,2.09,0,0,0-.34,1.22H23.88A3.06,3.06,0,0,1,24.12,25ZM24,30a1.1,1.1,0,0,1-.33-.81,1.08,1.08,0,0,1,.33-.8,1.13,1.13,0,0,1,1.95.8A1.15,1.15,0,0,1,24,30Z"
                            />
                        </svg>
                        <div className="flex flex-col items-center">
                            <span className="font-bold uppercase text-[#31883A] text-sm">Fale connosco</span>
                            <span className="font-medium text-sm">Saiba mais entrando em contacto connosco</span>
                        </div>
                    </div>
                </div>
            </div>

            <section className="h-full">
                {/* <div className="container mx-auto grid grid-cols-[auto_1fr] gap-7 h-full"> */}
                <div className="h-full flex justify-center">
                    {/* <SideBar /> */}

                    <div className="h-full">
                        <div className="flex flex-col items-center mt-10">
                            <h1 className="text-3xl mb-5 text-[#31883A]">Quem Somos</h1>
                            <p className="text-[#31883A] text-sm"><strong>{`A NL Capapelo (SU), LDA é o parceiro de confiança das empresas em Angola.`}</strong></p>

                            <p className="text-sm">{`Somos uma empresa 100% angolana, com actividade principal a comercialização de materiais diversos e bens alimentares ao sector petrolífero e em geral.`}</p>
                        </div>

                        <TopProducts data={data} />
                    </div>
                </div>
                <div className="w-full">
                    <div className="flex flex-col justify-center pr-[50%] pl-20 text-white gap-5" style={contacts}>
                        <div className="flex flex-col gap-5 text-base">
                            <h2 className="font-medium text-3xl">Precisa de ajuda?</h2>
                            <span>Para que não fique com dúvidas, entre em contacto connosco e saiba mais sobre nossos produtos e serviços por um agente NLC.</span>
                        </div>

                        <div>
                            <WhatsAppContact
                                transparent
                                label="Contacte-nos"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}