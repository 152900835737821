import React from 'react';
import Card from './Card';

interface IProduct {
    produto: string;
    descricao: string;
    imageUri: string;
}

type T = {
    data: IProduct[]
}

const TopProducts: React.FC<T> = ({ data }) => {
    return (
        <>
            {/* <!-- SECÇÃO PRODUCTOS EM DESTAQUE START --> */}
            <section className="mt-10">
                <h1 className="text-center mb-4 text-[#31883A] text-3xl">
                    Productos em destaque
                </h1>
                <div className="mx-7 bg-gray-900/75 w-screen flex justify-center">
                    <div className="py-5">
                        <div className="grid grid-cols-4 gap-4 h-full">
                            {
                                data.slice(0, 8).map((item, index) => {
                                    return (
                                        <Card
                                            data={item}
                                            key={index}
                                            className="h-80 flex flex-col gap-2 bg-white border border-white  hover:border-gray-200 p-[10px] rounded-lg"
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- SECÇÃO PRODUCTOS EM DESTAQUE END --> */}
        </>
    );
}

export default TopProducts;