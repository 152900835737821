import React from 'react';

const FilterProducts: React.FC = () => {
    return (
        <>
            <h2 className="uppercase mb-4 flex gap-3 justify-center">
                <a
                    href="#"
                    className="font-bold text-emerald-800 bg-emerald-300 py-2 px-4 rounded-full hover:bg-emerald-300 hover:text-primary-darkest cursor-pointer border border-solid border-emerald-400"
                >Todos Productos</a
                >
                <span
                    className="font-bold text-gray-200 bg-gray-400 py-2 px-4 rounded-full  hover:text-primary-darkest border border-solid border-gray-500 cursor-default"
                >Manutençao</span
                >
                <span
                    className="font-bold text-gray-200 bg-gray-400 py-2 px-4 rounded-full  hover:text-primary-darkest border border-solid border-gray-500 cursor-default"
                >Protecção</span
                >
                <span
                    className="font-bold text-gray-200 bg-gray-400 py-2 px-4 rounded-full  hover:text-primary-darkest border border-solid border-gray-500 cursor-default"
                >Material eléctrico</span
                >
                <span
                    className="font-bold text-gray-200 bg-gray-400 py-2 px-4 rounded-full  hover:text-primary-darkest border border-solid border-gray-500 cursor-default"
                >Bombagem</span
                >
                <span
                    className="font-bold text-gray-200 bg-gray-400 py-2 px-4 rounded-full  hover:text-primary-darkest border border-solid border-gray-500 cursor-default"
                >Soldadura</span
                >
            </h2>
        </>
    );
}

export default FilterProducts;