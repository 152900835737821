const COVER = require("../assets/images/life-jacket.jpg")
const MAINTENANCE = require("../assets/images/maitenance.jpg")
const WALLPAPER = require("../assets/images/wallpaper.jpg")
const LOGOY = require("../assets/images/logo-vertical-92.png")
const CONTACTUS = require("../assets/images/contactus.jpg")

export default {
    COVER,
    LOGOY,
    MAINTENANCE,
    WALLPAPER,
    CONTACTUS
}