import React from 'react';
import Button, { IPropsButton } from './button';

const URL = "https://wa.me";

interface IProps extends IPropsButton {
    number?: string;
    message?: string;
    children?: React.ReactElement;
    onClick?: (e: any) => void;
}

const WhatsAppContact: React.FC<IProps> = ({ number, message, children, onClick, ...props }) => {
    number = number?.replace(/[^\w\s]/gi, "").replace(/ /g, "");

    let url = `${URL}/+244945340710`;

    if (message) {
        url += `?text=${encodeURI(message)}`;
    }

    return (
        <>
            <Button
                {...props}
                onClick={(e) => {
                    window.open(url);

                    if (onClick) {
                        onClick(e);
                    }
                }}
            >
                {children}
            </Button>
        </>
    );
}

export default WhatsAppContact;