import React from 'react';

const Page404: React.FC = () => {
    return (
        <div className="flex flex-col h-screen items-center justify-center">
            <span className="uppercase font-bold text-2xl italic ">404</span>
            <span className="uppercase font-bold text-xl italic ">Página não encontrada</span>
        </div>
    );
}

export default Page404;