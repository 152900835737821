import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { Home } from "../pages/Home";
import { Products } from "../pages/Products";
import { AboutUs } from "../pages/AboutUs";
import { Contacts } from "../pages/Contacts";
import { Shop } from "../pages/Shop";
import Page404 from "../pages/Page404";


export function MainRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Shop />} />
      <Route path="/inicio" element={<Navigate replace to="/" />} />
      <Route path="/produtos" element={<Products />} />
      {/* <Route path="/quem-somos" element={<AboutUs />} /> */}
      <Route path="/contactos" element={<Contacts />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
