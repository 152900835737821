import React, { useEffect, useState } from 'react';
import FilterProducts from './FilterProducts';
import Card from './Card';
import { api } from '../../../services/api';

interface IProduct {
    produto: string;
    descricao: string;
    imageUri: string;
}

type T = {
    data: IProduct[]
}

const ProductList: React.FC<T> = ({ data }) => {
    // const totalPages = Math.ceil(newData?.length / 10);
    // const [page, setPage] = useState(1);

    // function goToFistPage() {
    //     setPage(1);
    // }

    // function goToLastPage() {
    //     setPage(totalPages);
    // }

    // function goToNextPage() {
    //     setPage(page + 1);
    // }

    // function goToPreviousPage() {
    //     setPage(page - 1);
    // }

    return (
        <>
            {/* <!-- SECÇÃO PRODUCTOS POR CATEGORIA START --> */}
            <section className="mt-10">
                <FilterProducts />

                <div className="mx-7">
                    <div className="grid grid-cols-4 gap-4 h-full">
                        {
                            data.map((item, index) => {
                                return (
                                    <Card data={item} key={index} />
                                )
                            })
                        }
                    </div>
                </div>

                {/* <div className="py-3 px-4 text-sm text-zinc-300 text-right" colSpan={3}>
                            <div className="inline-flex items-center gap-8">
                                <span>{`Página ${page} de ${totalPages !== 0 ? totalPages : 1}`}</span>

                                <div className="flex gap-1.5">
                                    <IconLabelButton
                                        name={ICONS.PREVIOUS}
                                        size={16}
                                        onClick={goToFistPage}
                                        disabled={page === 1 || newData.length === 0}
                                    />
                                    <IconLabelButton
                                        name={ICONS.ARROW_BACK}
                                        size={16}
                                        onClick={goToPreviousPage}
                                        disabled={page === 1 || newData.length === 0}
                                    />
                                    <IconLabelButton
                                        name={ICONS.ARROW_NEXT}
                                        size={16}
                                        onClick={goToNextPage}
                                        disabled={page === totalPages || newData.length === 0}
                                    />
                                    <IconLabelButton
                                        name={ICONS.NEXT}
                                        size={16}
                                        onClick={goToLastPage}
                                        disabled={page === totalPages || newData.length === 0}
                                    />
                                </div>
                            </div>
                        </div> */}
            </section>
            {/* <!-- SECÇÃO PRODUCTOS POR CATEGORIA END --> */}
        </>
    );
}

export default ProductList;