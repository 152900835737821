import { BrowserRouter as Router } from "react-router-dom";
import { MainRoutes } from "./routes";
// import { NavBar } from "../components/NavBar";
// import { Footer } from "../components/Footer";
import { Shop } from "../pages/Shop";
import NavBar from "../pages/Shop/components/NavBar";
import TopBar from "../pages/Shop/components/TopBar";
import Footer from "../pages/Shop/components/Footer";

export function Routes() {
  return (
    <>
      <Router>
        {/* <NavBar /> */}
        {/* <MainRoutes /> */}
        {/* <Footer /> */}

        {/* <Shop /> */}
        <TopBar />
        <NavBar />
        {/* <Shop /> */}
        <MainRoutes />
        <Footer />

      </Router>
    </>
  );
}
