import { useEffect, useState } from "react";
import { IMG } from "../../constants";
import ProductList from '../Shop/components/ProductList';
import { api } from "../../services/api";

export function Products() {
    const [data, setData] = useState([]);

    useEffect(() => {
        api.get("/produtos").then((response) => {
            if (response.data) {
                setData(response.data.produtos)
            }
        }).catch((err) => {
            console.error("Error in data fetch:", err);
        });
    }, [])

    return (
        <main className="h-full mb-5 w-screen flex justify-center">
            <div>
                <ProductList data={data} />
            </div>
        </main>
    )
}