import React from 'react';
import { IMG } from '../../../constants';
import Button from '../../../components/button';
import WhatsAppContact from '../../../components/WhatsAppContact';
import { twMerge } from 'tailwind-merge';

interface ICard {
    produto: string;
    descricao: string;
    imageUri: string;
}

interface T extends React.ComponentProps<"article"> {
    data?: ICard;
}

const Card: React.FC<T> = ({ data, ...props }) => {
    const img = {
        backgroundImage: `url(${data?.imageUri})`,
        backgroundSize: 'cover',
        // backgroundSize: 'stretch',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        // overflow: 'hidden',
        height: "100%",
        width: "100%"
    };

    const setMessageWhatsapp = `*SOLICITAÇÃO DE PREÇO* \n*>>> PRODUTO:* ${data?.produto}\n*>>> DESCRIÇÃO:* ${data?.descricao}\n*>>> SDK:*`

    return (
        <>
            <article className={twMerge("h-80 flex flex-col gap-2 border border-white hover:border-gray-200 p-[10px] rounded-lg")} {...props}>
                <div
                    className="h-48 flex align-center justify-center p-1"
                >
                    {/* <img
                        className="h-full"
                        src="images/products/product-1.jpg"
                        alt="product-x-title"
                    /> */}
                    <div style={img} />
                </div>
                <div className="flex flex-col gap-2 grow justify-between p-2">
                    <div>
                        <p
                            className="uppercase text-xs font-bold tracking-wider text-gray-700 mb-2"
                        >
                            {data?.descricao}
                        </p>
                        <p className="uppercase">{data?.produto}</p>
                    </div>

                    <WhatsAppContact
                        label="Preço sob consulta"
                        message={setMessageWhatsapp}
                    />
                </div>
            </article>
        </>
    );
}

export default Card;