import React from "react";

import { Routes } from "./routes";

function App() {
  return (
    <main>
      <Routes />
    </main>
  );
}

export default App;