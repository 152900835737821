import React from 'react';
import { IMG } from '../../../constants';
import { Link } from 'react-router-dom';

const TopBar: React.FC = () => {
    const img = {
        backgroundImage: `url(${IMG.LOGOY})`,
        // backgroundSize: 'cover',
        // backgroundSize: 'stretch',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        // overflow: 'hidden',
        height: 90,
        width: 90
    };

    return (
        <>
            {/* <!-- INFOBAR START --> */}
            <section className="p-1 border-b border border-gray-200">
                <div className="flex justify-between container mx-auto">
                    <p>Melhorar para servir melhor o cliente!</p>
                    <div className="flex gap-x-3">
                        <div className="flex gap-x-1 items-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="size-4"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                                />
                            </svg>
                            <span>+244 945 340 710</span>
                        </div>
                        <div className="flex gap-x-1 items-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="size-4"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                                />
                            </svg>
                            <span>nlc@nlclimitada.com</span>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- INFOBAR END --> */}

            {/* <!-- SEARCH START --> */}
            <section className="">
                <div className="container mx-auto">
                    <div className="flex items-center justify-between py-2">
                        <div className="h-20 flex flex-1 items-center gap-2">
                            {/* <img
                                className="h-full"
                                src="../../../assets/images/logo-vertical-92.png"
                                alt="Logotipo da empresa NL CAPAPELO SU LDA"
                            /> */}
                            <Link to="/"><div style={img} /></Link>
                            {/* <span className="uppercase text-[48px] font-bold text-red-600">NL CAPAPELO</span> */}
                        </div>
                        <div
                            className="flex items-center basis-[300px] border-solid border border-slate-300 rounded-lg overflow-hidden"
                        >
                            <input
                                className="px-4 py-2 grow"
                                type="search"
                                name=""
                                id=""
                                placeholder="Pesquise o produto ..."
                                disabled
                            />
                            <span className="px-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="size-6"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                                    />
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- SEARCH END --> */}
        </>
    );
}

export default TopBar;