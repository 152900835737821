import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
    return (
        <>
            {/* <!-- FOOTER START --> */}
            <footer className="pt-8 pb-6 bg-blue-950 text-white text-sm">
                <div className="">
                    <div className="container mx-auto">
                        <div className="grid grid-cols-[2fr_1fr_3fr] mb-3">
                            <ul>
                                <li className="uppercase mb-3 font-bold">Contactos</li>
                                <li>NL CAPAPELO (SU), LDA</li>
                                {/* <li>NIF: XXXXXXXXXX</li> */}
                                <li>Estrada de Cacuaco, Nova Urbanização de Cacuaco</li>
                                <li>Luanda &mdash; Angola</li>
                                <li>{"(+244) 924 081 038, 945 340 710"}</li>
                                <li>nlclimitada@nlclimitada.com</li>
                            </ul>
                            <ul>
                                <li className="uppercase mb-3 font-bold">Empresarial</li>
                                <Link to="/produtos"><li className="hover:text-[#31883A]">Productos</li></Link>
                                {/* <Link to="/quem-somos"><li className="hover:text-[#31883A]">Sobre nós</li></Link> */}
                                <Link to="/contactos"><li className="hover:text-[#31883A]">Contacte-nos</li></Link>
                            </ul>
                            <div>
                                <p className="uppercase mb-3 font-bold">Newsletter</p>
                                <form className="flex gap-2" action="#">
                                    <input
                                        className="border border-gray-500 py-2 px-4 grow"
                                        type="text"
                                        placeholder="Email para receber as promoções"
                                        disabled
                                    />
                                    <button
                                        className="py-2 px-4 bg-emerald-600 border border-emerald-600"
                                        disabled
                                    >
                                        Inscrever-se
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="border-t border-gray-200 pt-3 w-[80%] flex self-center" />
                    <div className="container mx-auto">
                        <div className="flex justify-between">
                            <p>&copy; 2024 NL CAPAPELO &mdash; Todos os direitos reservados</p>
                            <p>
                                Projectado e construido pela
                                <span className="uppercase font-semibold"> Cemerson</span>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            {/* <!-- FOOTER END --> */}
        </>
    );
}

export default Footer;